import React, {useState, useEffect, useRef, useCallback, useContext} from 'react'
import { store } from '../productsStore/Store'
import "../styles/BestSellers.css";
import HoverImage from 'react-hover-image/build';
import { Link } from 'react-router-dom';
import Api from "../api/api"
import apiUtil from '../api/apiUtil';
import Loading from '../assets/loading.gif'

const BestSellers = () => {

  const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        callReadApi();
      }, [])
    
      const callReadApi = useCallback(() => {
    
        let param ='';
    
         param = {
          };
        
          setIsLoading(true);
          const getData = Api.read('products', param)
          
          Promise.all([getData]).then( (results) => {
              apiUtil.parseResult(results[0], (data) => {
                const dataArr = (data.data && data.data.length > 0) ? data.data : []
                const transformedData = dataArr && dataArr.map(product => ({
                    id: product.id,
                    name: product.name,
                    price: product.price,
                    type: 'bestSeller', // example logic for type
                    primaryImage: product.feature_image_url,
                    hoverImg: product?.images?.length > 0 ? product.images[0].file_url : product.feature_image_url,
                    gallery_images: product.images,
                    description: product.description,
                    quantity: product.quantity,
                  }));

                setData(transformedData);
                setIsLoading(false);
    
              }, (error, type) => {
                setData([]);
                setIsLoading(false);
              });
          });
      }, [])

  if (isLoading) {
    return <div className="text-center my-5 d-flex justify-content-center align-items-center" style={{minHeight: '400px'}}>
      <img src={Loading} className="img-fluid" style={{objectFit: 'cover', width : '300px', height: '300px'}} />
    </div>;
  }
  
  return (
    <div className='bestSellerMainParent flex flex-row'>
      {data.map((item) => {
        if (item.type == "bestSeller")
          return (
            <Link to={`/${item.id}`} key={item.id}>  
            <div key={item.id} className="bestSellerIndivitualItem">
              <HoverImage src={item.primaryImage} hoverSrc={item.hoverImg} className=" bestSellerImage border rounded rounded-xl mb-6" 
              style={{objectFit: 'cover', width: '200px', height: '200px'}}/>
              <p className='bestSellerName text-center mb-2'> {item.name} </p>
              <p className=' font-normal text-center'> ${item.price} </p>
            </div>
            </Link>
          )
      })}

    </div>
  )
}

export default BestSellers;
