import React from 'react'
import GooglePayButton from "@google-pay/button-react";
import { PayPalButtons } from '@paypal/react-paypal-js';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { useEffect } from 'react';
import CartAdditonalFeatures from './CartAdditonalFeatures';
import CartPageFaq from './CartPageFaq';
import Features2 from './Features2';
import { useSelector } from 'react-redux';
import StripeCheckoutButton from '../components/stripe/StripeCheckOut'

const CartTotal = (props) => {

    let finapPr = props.totalPr + 20 + 15;

    let cartItems = useSelector((state) => state.cart.items);

    let cartLen = cartItems.length;


    return (
        <div className='mt-4 pt-4 px-4'>
            <div className='d-flex align-items-center justify-content-between mt-4 pt-4 px-4'>
                <p className='fw-medium fs-3'> SUBTOTAL </p>
                <p className='fw-medium fs-3'> ${Math.round(props.totalPr)} </p>
            </div>

            {/* <div className='d-flex align-items-center justify-content-between pt-4 px-4'>
                <p className='fs-5'> SHIPPING </p>
                <p className='fs-5'> $20 </p>
                
            </div>

            <div className='d-flex align-items-center justify-content-between pt-4 px-4'>
                <p className='fs-5'> INCL, TAX  </p>
                <p className='fs-5'> $15 </p>
            </div> */}

           <hr />

            <div className='fw-bold fs-3 text-center'>
                <p> TOTAL  ${Math.round(props.totalPr)}</p>
            </div>

            <hr />

            <div className='d-flex text-center mt-4 w-100'>
                <StripeCheckoutButton cartItems={cartItems} />
            </div>

           

            {cartLen > 1 ? <div className='mobDisappear mt-4'>

                <p className='relative fof fs-5 text-center top-9'> SECURELY CHCECKOUT WITH </p>

                <CartAdditonalFeatures />

                {cartLen > 2 ? <Features2 /> : ""}

            </div>
                :
                ""
            }

        </div>
    )
}

export default CartTotal

