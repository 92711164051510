import React from 'react'
import better from "../assets/better.jpeg";
import mastercard from "../assets/mastercard.png";
import { FaShippingFast } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { FaCcVisa } from "react-icons/fa";
import { FaCcPaypal } from "react-icons/fa";
import { FaCcStripe } from "react-icons/fa";
import { FaCcMastercard } from "react-icons/fa";




const CartAdditonalFeatures = () => {
    return (
        <div className='d-flex justify-content-evenly align-items-center mt-4 pt-4'>
            <FaCcVisa style={{width: '50px', height: '50px'}} />
            <FaCcPaypal style={{width: '50px', height: '50px'}} />
            <FaCcMastercard style={{width: '50px', height: '50px'}} />
            <FaCcStripe style={{width: '50px', height: '50px'}} />
        </div>
    )
}

export default CartAdditonalFeatures