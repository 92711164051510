// src/components/AboutUs.js
import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import '../../styles/AboutUs.css'; // Import the CSS file for styling
import Logo from '../../assets/logo.png'; 

const AboutUs = () => {
  return (
    <Container className="about-us-container my-5">
      <Row className="mb-4">
        <Col>
          <h1 className="text-center">About Us</h1>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={6} className="d-flex align-items-center">
          <div>
            <h2>Our Mission</h2>
            <p>
              At Smart Stickies, our mission is to revolutionize connectivity by making everyday objects smarter and more interactive. We aim to be a global leader in driving digital innovation, helping businesses and individuals to enhance their operational efficiency and information-sharing capabilities.
            </p>
          </div>
        </Col>
        <Col md={6}>
          <Image src={Logo} fluid style={{objectFit: 'contain', overflow: 'hidden', height : '300px'}}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={6}>
          <Image src={Logo} fluid style={{objectFit: 'contain', overflow: 'hidden', height : '300px'}}/>
        </Col>
        <Col md={6} className="d-flex align-items-center">
          <div>
            <h2>Our Vision</h2>
            <p>
              Our vision is to seamlessly integrate digital technology into everyday life, making it accessible and beneficial for all. We strive to provide innovative solutions that are user-friendly, secure, and highly effective.
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className="text-center">Meet Our Team</h2>
          <p className="text-center">
            Our dedicated team of professionals is committed to delivering the best products and services to our customers. We work collaboratively to innovate and create solutions that meet the evolving needs of our clients.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutUs;
