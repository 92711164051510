

export const store = [
    // best sellers 4
    {
        id: "1",
        name: "Facial Cleanser",
        price: 9,
        type: "bestSeller",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Foaming_Facial_Cleanser_125ml_01_Product_540x.jpg?v=1599504600",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Foaming_Facial_Cleanser_125ml_OH_01_1_540x.jpg?v=1599504601",

    },

    {
        id: "2",
        name: "Facial Moisturiser",
        price: 9,
        type: "bestSeller",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Facial_Moisturiser_125ml_01_Product_540x.jpg?v=1599504612",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Facial-Moisturiser_01_540x.jpg?v=1599504612",

    },

    {
        id: "3",
        name: "Mist Toner",
        price: 9,
        type: "bestSeller",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Hydrating_Mist_Toner_125ml_01_Product_540x.jpg?v=1599504641",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Hydrating_Mist_Toner_125ml_OH_01_540x.jpg?v=1599504641",

    },

    {
        id: "4",
        name: "Face Mask",
        price: 9,
        type: "bestSeller",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Sukin_Super_Greens_Detoxifying_Facial_Masque_540x.jpg?v=1599504639",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Detoxifying-Clay-Masque_01_540x.jpg?v=1599504639",

    },

    // for navBar ka gift type

    {
        id: "6",
        name: "ROSE GOLD PACK",
        price: 12,
        type: "gift",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Rose_Gift_Pack_Front_360x.jpg?v=1602265134",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Rose_Gift_Pack_Angle_360x.jpg?v=1602265134",

    },

    {
        id: "7",
        name: "HAND CREAM TRIO PACK",
        price: 10,
        type: "gift",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Hand_Cream_Trio_Gift_Pack_Front_360x.jpg?v=1602265134",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Hand_Cream_Trio_Gift_Pack_Angle_360x.jpg?v=1602265134",

    },

    {
        id: "8",
        name: "THE LAVENDER PACK",
        price: 15,
        type: "gift",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/TheLavenderPack1000x1000_360x.jpg?v=1604413498",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/9327693009396_-TheLavenderPack_360x.jpg?v=1602265136",

    },

    // for NAv bar ka SHOP RANGE "body type"

    {
        id: "9",
        name: "COFFEE BODY SCRUB",
        price: 11,
        type: "navbar-BodyType",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Masterbrand_Coffee___Coconut_Energising_Body_Scrub_200ml_01_Product_540x.jpg?v=1599504583",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Masterbrand_Coffee___Coconut_Energising_Body_Scrub_200ml_OH_01_540x.jpg?v=1599504583",

    },

    {
        id: "10",
        name: "COCONUT BODY WASH",
        price: 18,
        type: "navbar-BodyType",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Botanical_Body_Wash_1L_01_Product_540x.jpg?v=1599504644",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/AloeVera-styled-shot_c2cadf15-cec0-4788-8cb5-10c22e5ccfd7_540x.jpg?v=1599504644",

    },


    {
        id: "11",
        name: "COCONUT BODY WASH",
        price: 21,
        type: "navbar-BodyType",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Hydrating_Body_Lotion_500mL_01_Product_540x.jpg?v=1599504585",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/NO_Lists_for_Site-SIG_fd44fcf9-5f2e-4a03-9deb-06e175447fd7_540x.png?v=1599504585",

    },

    // for NAv bar ka SHOP RANGE "travel type"

    {
        id: "12",
        name: "FOAMING FACE WASH",
        price: 56,
        type: "navbar-TravelType",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Foaming_Facial_Cleanser_50mL_Tube_WEB.jpg?v=1599504567",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/IMG_8764_360x.jpg?v=1602265059",

    },

    {
        id: "13",
        name: "HYDRATING MIST TONER",
        price: 28,
        type: "navbar-TravelType",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Hydrating_Mist_Toner_50mL_WEB_540x.jpg?v=1599504599",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/239024Sukin-SocialContentShoot_321_360x.jpg?v=1599504599",

    },

    {
        id: "14",
        name: "REVITAL FACE WASH",
        price: 22,
        type: "navbar-TravelType",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Revitalising_Facial_Scrub_50mL_Tube_WEB_540x.jpg?v=1599504603",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/239024Sukin-SocialContentShoot_341_1_360x.jpg?v=1602265081",

    },


    {
        id: "15",
        name: "UBTABN FACEWASH",
        price: 44,
        type: "navbar-TravelType",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Natural_Balance_Shampoo_50mL_Tube_Grey_BG_FOR_WEB_540x.jpg?v=1599504616",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/239024Sukin-SocialContentShoot_341_360x.jpg?v=1599504616",

    },

    // for navbar ka new type ke liye 

    {
        id: "16",
        name: "ROSE WATER",
        price: 52,
        type: "navbar-NewType",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Masterbrand_Rose_Mist_Toner_125ml_01_Product_540x.jpg?v=1599504649",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Masterbrand_Rose_Mist_Toner_125ml_Green_Tea_Mist_Toner_125ml_01_540x.jpg?v=1599504649",

    },


    {
        id: "17",
        name: " COFFEE FACE MASK",
        price: 47,
        type: "navbar-NewType",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Sukin_Coffee_Coconut_Exfoliating_Masque_540x.jpg?v=1599504663",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Masterbrand_Coffee___Coconut_Exfoliating_Facial_Masque_100ml_OH_01_540x.jpg?v=1599504663",

    },


    {
        id: "18",
        name: "MIST TONER",
        price: 21,
        type: "navbar-NewType",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Sukin_Rosehip_Oil-25ml-Product_540x.jpg?v=1599504623",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/NO_Lists_for_Site-RH_62038fc0-558f-45e6-b0cd-061fba08bfd8_540x.png?v=1599504623",

    },

    // actual products "BEST SELLER PRODUCTS" type "ourBestSellers"

    {
        id: "19",
        name: "MIST TONER",
        price: 21,
        type: "ourBestSellers",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Hydrating_Mist_Toner_125ml_01_Product_540x.jpg?v=1599504641",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Hydrating_Mist_Toner_125ml_OH_01_360x.jpg?v=1599504641",

    },


    {
        id: "20",
        name: "ROSE GOLD FACIAL",
        price: 61,
        type: "ourBestSellers",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Masterbrand_Rose_Mist_Toner_125ml_01_Product_540x.jpg?v=1599504649",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Masterbrand_Rose_Mist_Toner_125ml_Green_Tea_Mist_Toner_125ml_01_360x.jpg?v=1599504649",

    },

    {
        id: "21",
        name: "FOAMING ROSE FACIAL",
        price: 45,
        type: "ourBestSellers",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Facial_Moisturiser_125ml_01_Product_540x.jpg?v=1599504612",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Facial-Moisturiser_01_540x.jpg?v=1599504612",

    },

    {
        id: "22",
        name: "REVITAL FACE WASH",
        price: 84,
        type: "ourBestSellers",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Micellar_Cleansing_Water_250ml_01_Product_540x.jpg?v=1599504588",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Micellar_Cleansing_Water_250ml_OH_01_540x.jpg?v=1599504588",

    },

    {
        id: "23",
        name: "EXFOLIATING MASK",
        price: 25,
        type: "ourBestSellers",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Cream_Cleanser_125ml_01_Product_540x.jpg?v=1599504664",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Cream_Cleanser_125ml_OH_01_540x.jpg?v=1599504664",

    },

    {
        id: "24",
        name: "APPLE CIDER CREAM",
        price: 45,
        type: "ourBestSellers",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Revitalising_Facial_Scrub_125ml_01_Product_540x.jpg?v=1599504656",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Hydrating_Facial_Masque_Revitalising_Facial_Scrub_125ml_OH_01_540x.jpg?v=1599504656",

    },

    {
        id: "25",
        name: "AVACODA CREAM",
        price: 157,
        type: "ourBestSellers",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Hydrating_Facial_Masque_01_Product_2_540x.jpg?v=1599504579",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Hydrating_Facial_Masque_OH_01_540x.jpg?v=1599504579",

    },


    {
        id: "26",
        name: "UBTAN FACEWASH",
        price: 24,
        type: "ourBestSellers",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Antioxident_Eye_Serum_01_Product_540x.jpg?v=1599504580",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Antioxidant_Eye_Cream_30ml_01_540x.jpg?v=1599504580",

    },

    {
        id: "27",
        name: "ANTIOXIDANT FACEMASK",
        price: 75,
        type: "ourBestSellers",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Sukin-Paw-Paw_540x.jpg?v=1599504587",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/sukin_pawpaw_540x.jpg?v=1599504587",

    },

    {
        id: "28",
        name: "COFFEE BODY WASH",
        price: 78,
        type: "ourBestSellers",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Moisture_Restoring_Night_Cream_120ml_01_Product_2_540x.jpg?v=1599504662",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Moisture_Restoring_Night_Cream_120ml_01_540x.jpg?v=1599504662",

    },

    {
        id: "29",
        name: "STRAWBERRY MOISTURISER",
        price: 57,
        type: "ourBestSellers",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Purifying_Facial_Masque_01_Product_2_540x.jpg?v=1599504582",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Hydrating_Facial_Masque_OH_01_540x.jpg?v=1599504579",

    },

    {
        id: "30",
        name: "BLUEBERRY MOISTURISER",
        price: 88,
        type: "ourBestSellers",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Hydrating_Body_Lotion_500ml_02_540x.jpg?v=1599504645",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Hydrating-Body-Lotion_02_540x.jpg?v=1599504645",

    },

    // for ig carosuel  type "IGPOSTS"

    {
        id: "31",

        type: "iGPosts",
        primaryImage: "https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313962865_130486163124825_3872437440492064747_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=-lmG2O4dOucAX8QcJ5M&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfAHT7pOcFYxRAnf56OnXFtLSyR7WayrzECFZkdsW9r_Rw&oe=63731B07",

    },

    {
        id: "32",
        type: "iGPosts",
        primaryImage: "https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/314474189_3459125960988849_5301653039305226582_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=Py6h3bGoC_MAX-Iews1&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfCRfHYUT_SCZWQYEKljTugVmBowTNDFYavEXU44ET63yg&oe=6372D043",

    },

    {
        id: "33",
        type: "iGPosts",
        primaryImage: "https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/314478179_427847786212552_2166145489506263286_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=2EMtwONXdlgAX-YTXAC&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfCNZD2XX5ypy58-6eUsbaHb2adjwe2LT6_UhoyCDB-JEQ&oe=637318F2",

    },

    {
        id: "34",
        type: "iGPosts",
        primaryImage: "[https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313518379_188429183711300_8151278366243215086_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=HQxZfX3zuaQAX_zfcBH&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfC-0dxgjeZAMW4j5Und2ZipxnBPJgAAw0eIHPY1NTH3vA&oe=6373ECA3](https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313518379_188429183711300_8151278366243215086_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=HQxZfX3zuaQAX_zfcBH&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfC-0dxgjeZAMW4j5Und2ZipxnBPJgAAw0eIHPY1NTH3vA&oe=6373ECA3)",

    },


    {
        id: "35",
        type: "iGPosts",
        primaryImage: "https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313904838_644828754033309_8190401506066273847_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=5vGwQp3agyYAX9GzdN9&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfCpWTRt3wjB65-i_cMABs2uurxkJxlaDO-j0ZAPoeR3lw&oe=63723C04",

    },


    {
        id: "36",
        type: "iGPosts",
        primaryImage: "https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313842695_842398890281843_8923876477054992822_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=FKdEGPiWctQAX9PD1Tt&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfD5A7KTnVB45p6M9Xl9zQSm15B1vnoU6JASPBgW62G_JA&oe=6373D0C0",

    },

    {
        id: "37",
        type: "iGPosts",
        primaryImage: "https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313360482_421805233489433_2180398075982936387_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=TC_YP3KsBKgAX_UWuiL&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDL0rgDBFQc5kpLJiasFCbpam_yHNOF1Eqr6tkjJyjW0w&oe=6372D9EE",

    },

    {
        id: "38",
        type: "iGPosts",
        primaryImage: "[https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/312923519_415357727475132_3000659168966820929_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=MSPAwUxDLT0AX_q9fA5&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDkzxVRC1ejkowXInjU7aaYjuS6iDBJV372Q0p9R1Amkg&oe=63738D15](https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/312923519_415357727475132_3000659168966820929_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=MSPAwUxDLT0AX_q9fA5&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDkzxVRC1ejkowXInjU7aaYjuS6iDBJV372Q0p9R1Amkg&oe=63738D15)",

    },

    {
        id: "39",
        type: "iGPosts",
        primaryImage: "[https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/314538850_842391733849053_5837409325218948914_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=yl2x9QL1CI0AX_3K42M&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDtUpG3NMqPdhdjhPSsqtXIWhp63oIPCOXxHQUmp1Ro8A&oe=6373247D](https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/314538850_842391733849053_5837409325218948914_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=yl2x9QL1CI0AX_3K42M&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDtUpG3NMqPdhdjhPSsqtXIWhp63oIPCOXxHQUmp1Ro8A&oe=6373247D)",

    },

    {
        id: "40",
        type: "iGPosts",
        primaryImage: "https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313918031_164815222899324_5118105442635469994_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=75C5M_xl7W4AX9aXcJZ&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfBPLXtxavxi0qLvoBLwTHOcTh_WqHuC3dhATNZ7FhFVyw&oe=6372D076",

    },

    {
        id: "41",
        type: "iGPosts",
        primaryImage: "[https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313518379_188429183711300_8151278366243215086_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=HQxZfX3zuaQAX_zfcBH&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfC-0dxgjeZAMW4j5Und2ZipxnBPJgAAw0eIHPY1NTH3vA&oe=6373ECA3](https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313518379_188429183711300_8151278366243215086_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=HQxZfX3zuaQAX_zfcBH&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfC-0dxgjeZAMW4j5Und2ZipxnBPJgAAw0eIHPY1NTH3vA&oe=6373ECA3)",

    },

    {
        id: "42",
        type: "iGPosts",
        primaryImage: "https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313859159_1178762139722510_2208267463138928303_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=fDD-DllQ_fgAX_gepIB&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfA22pcsJqnauAqOBE2egFF1VhPe-wEPMDIQMOxhw2zCMw&oe=63741165",

    },


    // FOR UNDER 20 PAGE



    // body wash
    {
        id: "43",
        name: "NATURAL LEMON BODY WASH",
        price: 18,
        type: "bodywash all",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/NB_ScalpScrub200mlTube_200mL_02_large.jpg?v=1654006879",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/NO_Lists_for_Site-SIG_3ec6e6c0-aee5-483d-a6c9-e4abab736fd7_large.png?v=1654006879",
        stars: 4,
        descr: "Our lightweight Leave-In Conditioner hydrates, gently detangles and tames frizz leaving hair smooth and manageable.            "

    },

    {
        id: "44",
        name: "NATURAL STRAWBERRY BODY WASH",
        price: 15,
        type: "bodywash all",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/NB_Leave-InConditioner_250ml_02_large.jpg?v=1654006878",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/NaturalBalance_7be6d9d2-0387-44f2-9949-b03a344fc044_large.jpg?v=1654006878",
        stars: 5,
        descr: "Our Scalp Scrub removes product build up and impurities with a refreshing blend of Peppermint & Ginger Extract.        "

    },

    {
        id: "53",
        name: "NATURAL UBTAN BODY WASH",
        price: 15,
        type: "bodywash all",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/ROW_PAMicroExfoliatingCleanser125mL_1500px_large.jpg?v=1618352423",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/ROW_PAMicroExfoliatingCleanser125mL_1500px_large.jpg?v=1618352423",
        stars: 5,
        descr: "Hydrate and protect dry hands while caring for nails and cuticles through Soy Protein, Aloe Vera & Vitamin E"

    },





    {
        id: "54",
        name: "NATURAL NEEM BODY WASH",
        price: 11,
        type: "bodywash all",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Colour_Care_Lustre_Masque_200mL_02_For_Web_large.jpg?v=1616509409",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/ColourCare2_large.jpg?v=1616509409",
        stars: 5,
        descr: "Seriously hydrate the delicate under eye area with our fragrance-free water gel.            "

    },


    // body loption
    {
        id: "46",
        name: "NATURAL BLUEBERRY BODY LOTION",
        price: 15,
        type: "bodyLotion all",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/CreamyBodyWash500ml_large.jpg?v=1649168513",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/CreamyBodyFinal_large.jpg?v=1649168513",
        stars: 5,
        descr: "Deliver an instant boost of moisture to skin with our ultra-hydrating gel cream."
    },

    {
        id: "47",
        name: "NATURAL KIWI BODY LOTION",
        price: 19,
        type: "bodyLotion all",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Biomarine_Facial_Serum_02_FOR_WEB_large.jpg?v=1646144556",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/MicrosoftTeams-image_5_-SMALL_large.jpg?v=1646144556",
        stars: 5,
        descr: "Our glow-promoting cleanser gently exfoliates to remove dead skin cells, excess sebum & make-up."
    },

    {
        id: "48",
        name: "NATURAL AVACODA BODY LOTION",
        price: 14,
        type: "bodyLotion all",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Hydra_Eye_Gel_15ml_Tube_02_FOR_WEB_large.jpg?v=1646144555",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/MicrosoftTeams-image_5_-SMALL_533a775e-6e81-48de-bafa-6250300a0219_large.jpg?v=1646144555",
        stars: 5,
        descr: "Our glow-promoting exfoliator is formulated with Kakadu plum, a natural source of Vitamin C, and Bromelain, a natural fruit...            "

    },


    {
        id: "55",
        name: "NATURAL LITCHI BODY LOTION",
        price: 16,
        type: "bodyLotion all",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/ROW_SPFSheerTouchFacialSunscreen-Light-MediumTubeFRONT02_1000_large.jpg?v=1614090206",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/SukinSPF30UCG_d249fc5d-2cd5-4be7-afbd-8f11d545261f_large.jpg?v=1614090207",
        stars: 5,
        descr: "Kakadu Plum, a natural source of Vitamin C, Vitamin E and Australian bioactive complex rich in antioxidants brighten and...            "

    },

    {
        id: "56",
        name: "NATURAL TEATREE BODY LOTION",
        price: 18,
        type: "bodyLotion all",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/ROW_SPFSheerTouchFacialSunscreen-UntintedTubeFRONT02_1000px_large.jpg?v=1614090205",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/SukinSPF30UCG_large.jpg?v=1614090205",
        stars: 5,
        descr: "Our Scalp Scrub removes product build up and impurities with a refreshing blend of Peppermint & Ginger Extract.        "

    },

    {
        id: "57",
        name: "NATURAL TEATREE BODY LOTION",
        price: 18,
        type: "bodyLotion all",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Sukin_Rosehip_Facial_Masque_large.jpg?v=1599504675",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/STYLED_ROSE_FACIAL_MASQUE_large.jpg?v=1599504675",
        stars: 5,
        descr: "Our soap-free Bergamot & Patchouli Body Wash delights and stimulates the senses while leaving skin clean and fresh.            "

    },

    //// bodyScrub

    {
        id: "49",
        name: "EXFOLIATING JELLY SCRUB",
        price: 19,
        type: "bodyScrub all",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Brightening_JellyExfoliator125ml_02_large.jpg?v=1629210220",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/NO_Lists_for_Site_BRIGHTENING_451cbd3f-1e8b-446a-a45e-61cc31826f69_large.jpg?v=1629210220",
        stars: 5,
        descr: "Our cleanser helps exfoliate dead skin cells & cleanses away impurities to promote a more even complexion.            "

    },

    {
        id: "50",
        name: "EXFOLIATING APPPLE SCRUB",
        price: 9,
        type: "bodyScrub all",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Brightening_IlluminatingMoisturiser60ml_02_large.jpg?v=1629210218",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/NO_Lists_for_Site_BRIGHTENING_cf1ff5a7-5a32-4bb0-a7ce-be69ccc9fcf0_large.jpg?v=1629210219",
        stars: 5,
        descr: " Our Color Luster Mask restores natural shine and radiance to color treated hair with Kakadu Plum & Birch Extracts.            "

    },

    {
        id: "51",
        name: "EXFOLIATING KIWI SCRUB",
        price: 16,
        type: "bodyScrub all",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Bergamot_PachouliBodyWash500mL1000x1000x144_large.jpg?v=1637072534",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Bergamot_PachouliBodyWashpumpLifestyle1000x1000x144_large.jpg?v=1637072534",
        stars: 5,
        descr: "Our Scalp Scrub removes product build up and impurities with a refreshing blend of Peppermint & Ginger Extract.        "

    },

    {
        id: "58",
        name: "EXFOLIATING NEEM SCRUB",
        price: 16,
        type: "bodyScrub all",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Hydrating_Treatment_Oil_50mL_Bottle_01_2_large.jpg?v=1599504672",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Hydrating_Hair_Oil_large.jpg?v=1599504672",
        stars: 5,
        descr: "Our natural, sheer touch tinted sunscreen for light to medium skin tones has been designed with daily use in...            "

    },

    {
        id: "59",
        name: "EXFOLIATING COCOA SCRUB",
        price: 16,
        type: "bodyScrub all",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Sukin_Coffee_Coconut_Exfoliating_Masque_large.jpg?v=1599504663",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Masterbrand_Coffee___Coconut_Exfoliating_Facial_Masque_100ml_OH_01_large.jpg?v=1599504663",
        stars: 5,
        descr: "Our 100% naturally derived, sheer touch facial sunscreen has been designed with daily use in mind, and heroes natural...            "

    },





]