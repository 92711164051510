import React from 'react';
import { Button, Card } from 'react-bootstrap';
import successImage from '../../assets/success.gif';
import '../../styles/PaymentSuccess.css';
import { Link, useLocation } from 'react-router-dom';

const PaymentSuccess = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderNumber = queryParams.get('order_number');

  return (
    <div className="payment-success-container">
      <Card className="text-center">
        <Card.Body className="text-center">
          <img src={successImage} alt="Success" className="success-icon" />
          <Card.Title className="text-center w-100"><div className="text-center w-100">Thank you for ordering!</div></Card.Title>
          <Card.Text>
             <div className="text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
          </Card.Text>
          <div className="button-group">
          <Link to={"/view-order/" + orderNumber} ><Button variant="outline-primary" className="mx-2">View Order</Button></Link>
            <Link to="/" ><Button variant="primary" className="mx-2">Continue Shopping</Button></Link>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default PaymentSuccess;
