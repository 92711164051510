import React from 'react';
import { Button, Card } from 'react-bootstrap';
import cancelImage from '../../assets/cancel.webp';  // Add a cancel image asset to your project
import '../../styles/PaymentCancel.css';
import { Link } from 'react-router-dom';

const PaymentCancel = () => {
  return (
    <div className="payment-cancel-container">
      <Card className="text-center">
        <Card.Body>
          <img src={cancelImage} alt="Cancel" className="cancel-icon" />
          <Card.Title className="text-center w-100">Payment Cancelled</Card.Title>
          <Card.Text className="text-center w-100 ">
            Your payment has been cancelled. Please try again or contact support for assistance.
          </Card.Text>
          <div className="">
            <Link to="/" ><Button className="mx-2 btn bg-black text-white">BACK TO HOME</Button></Link>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default PaymentCancel;
