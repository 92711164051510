import React, {useState, useEffect, useRef, useCallback, useContext} from 'react'
import "../styles/FollowONIG.css";
import "react-alice-carousel/lib/alice-carousel.css";
import { ExternalLink } from 'react-external-link';
import Marquee from "react-fast-marquee";
import Api from "../api/api"
import apiUtil from '../api/apiUtil';


const FollowONIG = () => {

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const responsive = {
    2000: {
      items: 11,
    },
    1600: {
      items: 6
    },
    1200: {
      items: 5,
    },
    800: {
      items: 3,
    },
    0: {
      items: 1,
    },
  };


  useEffect(() => {
    callReadApi();
  }, [])

  const callReadApi = useCallback(() => {

    let param ='';

     param = {
        is_truck_driver: true,
        require_worker_only: true,
        sorting_array: ["id desc"]
      };
    
      setIsLoading(true);
      const getData = Api.read('socials', param)
      
      Promise.all([getData]).then( (results) => {
          apiUtil.parseResult(results[0], (data) => {
            const dataArr = (data.data && data.data.length > 0) ? data.data : []
            setData(dataArr);
            setIsLoading(false);

            console.log('data', dataArr)
          }, (error, type) => {
            setData([]);
            setIsLoading(false);
          });
      });
  }, [])

  return (
    <div className=' flex flex-column gap-4'>
      <p className='fw-bold fs-2 text-center pt-4 w-100'> FOLLOW US ON INSTAGRAM </p>
      <div className='mb-4'>
        <Marquee pauseOnHover={true} speed={100} >
        {data.map((dd)=> {
          return <ExternalLink href={dd.social_url} >
            <img src={dd.image_url} className='img-fluid img-thumbnail mx-4' style={{objectFit: 'cover', height: '300px', width: '200px'}}/>
          </ExternalLink>
        })}

        </Marquee>
      </div>

    </div>



  )
}

export default FollowONIG
