import React, { useState, useEffect, useCallback } from 'react';
import "../styles/SearchBox.css";
import { AiFillStar } from "react-icons/ai";
import Highlighter from "react-highlight-words";
import Api from "../api/api";
import apiUtil from '../api/apiUtil';
import Loading from '../assets/loading.gif'

const SearchBox = (props) => {
  const { id } = props;
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [reviews, setReviews] = useState([]);

  const keywords = [
    { label: "smell", handler: () => setSearch("smell") },
    { label: "skin", handler: () => setSearch("skin") },
    { label: "fragrance", handler: () => setSearch("fragrance") },
    { label: "toner", handler: () => setSearch("toner") },
    { label: "hydrating", handler: () => setSearch("hydrating") },
    { label: "face", handler: () => setSearch("face") },
    { label: "mist", handler: () => setSearch("mist") },
    { label: "glow", handler: () => setSearch("glow") },
    { label: "boost", handler: () => setSearch("boost") },
  ];

  useEffect(() => {
    callReadApi();
  }, [id]);

  const callReadApi = useCallback(() => {
    setIsLoading(true);
    const getData = Api.details('review', id);
    
    Promise.all([getData]).then((results) => {
      apiUtil.parseResult(results[0], (data) => {
        const dataArr = data.data ? data.data.data : [];
        setReviews(dataArr);
        setIsLoading(false);
        console.log('data sss', dataArr, data.data);
      }, (error) => {
        setIsLoading(false);
        console.error('Error fetching reviews:', error);
      });
    });
  }, [id]);

  if (isLoading) {
    return <div className="text-center my-5 d-flex justify-content-center align-items-center" style={{minHeight: '400px'}}>
      <img src={Loading} className="img-fluid" style={{objectFit: 'cover', width : '300px', height: '300px'}} />
    </div>;
  }

  return (
    <div className='row mt-4 pt-4'>
      {/* <div className='col-12 col-lg-6 d-flex flex-column gap-2 mt-4 pt-4'>
        <p className='fw-medium fs-1'>Filter Reviews</p>
        <input
          type="text"
          placeholder="Search"
          className='form-control w-100 text-gray-400'
          onChange={e => setSearch(e.target.value)}
        />
      </div>
      <p className='fw-medium fs-3 mt-4'>POPULAR KEYWORDS</p>

      <div className='keyWordHold w40rem uppercase flex fof flex-wrap gap-11 mb-4'>
        {keywords.map((keyword, index) => (
          <p key={index} className='keyWord hover:bg-slate-200' onClick={keyword.handler}>
            {keyword.label.toUpperCase()}
          </p>
        ))}
      </div> */}

      <div className='searchItemsHold'>
        {reviews.filter(val => {
          if (search === "") {
            return val;
          }
          else if (val.description.toLowerCase().includes(search.toLowerCase())) {
            return val;
          }
          return null;
        }).map(i => { 
          return (
           <div key={i.id} className="d-flex align-items-center py-3">
            <div className='p-4'>
              <p className='firstLetterHold rounded-full text-center pt-1 mb-1 capitalize'>{i.name.charAt(0)}</p>
            </div>
            <div>
              <p className='font-semibold text-md mb-1 capitalize'>{i.name}</p>
              <p className='text-md mb-1 d-flex'>
                {[...Array(i.rating)].map((star, index) => <AiFillStar key={index} />)}
                {[...Array(5 - i.rating)].map((star, index) => <AiFillStar key={index} className='text-muted' />)}
              </p>
              <p className='text-gray-500 font-semibold mb-1 capitalize'>{i.title}</p>
              <Highlighter
                className='fof mb-1 w50rem capitalize mobdesc'
                highlightClassName="YourHighlightClass highlightWord"
                searchWords={[search]}
                autoEscape={true}
                textToHighlight={i.description}
              />
            </div>
            
            
          </div>
        )})}
      </div>
    </div>
  );
}

export default SearchBox;
