import React from 'react'
import { useSelector } from 'react-redux'
import { cartActions } from '../redux-state/CartState'
import { useDispatch } from 'react-redux'
import cartgif from "../assets/cartGif.gif";
import qty from "../assets/qty.png"

import { MdAdd } from "react-icons/md";
//import { GrFormSubtract } from "react-icons/gr"
import { RiSubtractFill } from "react-icons/ri"
import YouMayAlsoLike from './YouMayAlsoLike';
import SPFooter from './SPFooter';
import { Link } from 'react-router-dom';


const Cart = (props) => {

  const { title, quantity, price, id, image, total, cartLength } = props.item;



  const cartList = useSelector((state) => state.cart.items);



  const dispacth = useDispatch();


  const totalPrice = cartList.map(item => {
    return item.quantity * item.price;
  }).reduce((totalPrice, singleItemPrice) => totalPrice + singleItemPrice, 0);




  const removeItemFromCartHandler = () => {
    dispacth(cartActions.removeItemFromCart(id))
  }

  const addItemToCartHandler = () => {
    dispacth(
      cartActions.addItemToCart({
        id,
        price,
        title,
        image,
      })
    )
  }


  return (
    <div >


      {/*<p> items in your cart {cartLength}  </p>*/}

      <div className='row p-4'>

        <div className='col-12 col-lg-4'>

          <Link to={`/${id}`}>
            <img src={image} className="img-fluid border rounded" style={{objectFit: 'cover', width : '200px', height: '200px'}} />
          </Link>

        </div>
        <div className='col-12 col-lg-8 d-flex flex-column align-items-between justify-content-between'>
            <div>
              <div className='fw-medium fs-2 mt-0'> {title} </div>
              <p className='fs-2'>${price} </p>
            </div>
            <div className='bg-black text-white rounded w-50'>
              <div className='d-flex align-items-center justify-content-evenly'>
                <RiSubtractFill className='fs-1 ' onClick={removeItemFromCartHandler} />
                <span className='fw-bold fs-2'> {quantity} </span>
                <MdAdd className='fs-1' onClick={addItemToCartHandler} />
              </div>
            </div>
        </div>
      </div>
      <hr />
    </div>


  )
}

export default Cart

