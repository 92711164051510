import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { AiFillStar } from "react-icons/ai";
import feel from "../assets/feel.png";
import axios from 'axios';
import Api from "../api/api";
import apiUtil from '../api/apiUtil';
import { toast } from 'react-toastify';

const Review = (props) => {
  const { id } = props;
  const { product_id } = useParams();
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: '',
    email: '',
    title: '',
    description: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    callCreateApi();
  };

  const callCreateApi = useCallback((callback = null) => {
    setLoading(true);

    const reviewData = {
        ...form,
        rating,
        product_id: id,
      };

    Api.create('review', reviewData)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          setLoading(false);
          setForm({
            name: '',
            email: '',
            description: '',
            title: '',
          });
          setRating(0)
            apiUtil.toast('Review Successfully Submitted!', 'check_circle');
          if (callback) {
            callback();
          }
        }, (error, type) => {
          setLoading(false);
          setForm({
            name: '',
            email: '',
            description: '',
            title: '',
          });
          setRating(0)

          if (callback) {
            callback();
          }
        });
      });
  }, [form]);

  return (
    <div className="container my-4">
      <h2>Submit a Review</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group mt-3">
          <label className='fw-bold mb-2'>Rating</label>
          <div className='d-flex'>
            {[1, 2, 3, 4, 5].map((star) => (
              <AiFillStar
                key={star}
                size={30}
                className={star <= (hoverRating || rating) ? 'text-warning' : 'text-muted'}
                onMouseEnter={() => setHoverRating(star)}
                onMouseLeave={() => setHoverRating(0)}
                onClick={() => setRating(star)}
                style={{ cursor: 'pointer' }}
              />
            ))}
          </div>
        </div>
        <div className="form-group mt-3">
          <label className='fw-bold mb-2' htmlFor="name">Name</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={form.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group mt-3">
          <label className='fw-bold mb-2' htmlFor="email">Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={form.email}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group mt-3">
          <label className='fw-bold mb-2' htmlFor="title">Review Title</label>
          <input
            type="text"
            className="form-control"
            id="title"
            name="title"
            value={form.title}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group mt-3">
          <label className='fw-bold mb-2' htmlFor="description">Review Description</label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            value={form.description}
            onChange={handleInputChange}
            rows="4"
            required
          ></textarea>
        </div>
        <button type="submit" className="spAddTOCart mt-3" disabled={loading && true}>Submit Review</button>
      </form>
    </div>
  );
};

export default Review;
