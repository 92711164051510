import React from 'react'
import { useSelector } from 'react-redux';
import Cart from './Cart';
import CartPageFaq from './CartPageFaq';
import CartPageFooter from './CartPageFooter';
import CartTotal from './CartTotal';
import EmptyCart from './EmptyCart';
import MobileNav from './MobileNav';
import SPFooter from './SPFooter';
import YouMayAlsoLike from './YouMayAlsoLike';
import "../styles/BestSellers.css";

const CartHold = () => {
    const cartItems = useSelector((state) => state.cart.items);
    const cartLen = cartItems.length;

    const totalPrice = cartItems.map(item => {
        return item.quantity * item.price;
    }).reduce((totalPrice, singleItemPrice) => totalPrice + singleItemPrice, 0);


    return (
        <div>
            <MobileNav />
            {/*<p className=' font-bold text-2xl fof'>CART TOAL ${totalPrice} </p>*/}
            {cartLen === 0 ? <EmptyCart /> : <>
           <div className="container">
            <div className='row'>
                <div className='col-12 col-lg-6'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <p className='fw-medium fs-2'> Your Cart </p>
                        <p className='fw-bold fs-3'> {cartLen} items </p>
                    </div>
                    <hr />

                    <div className=''>
                        {cartItems.map((item) => (

                            <div className=''>

                                <Cart
                                    key={item.id}
                                    item={{
                                        id: item.id,
                                        title: item.name,
                                        quantity: item.quantity,
                                        total: item.totalPrice,
                                        price: item.price,
                                        image: item.picture,
                                        cartLength: cartItems.length,
                                    }}
                                />
                            </div>
                        ))}
                    </div>

                </div>
                <div className='col-12 col-lg-6'>
                <CartTotal totalPr={totalPrice} />
                </div>
            </div>
            </div>

                

            <p className='fof text-4xl italic text-center mt-4 py-4'> YOU MAY ALSO LIKE </p>
                <YouMayAlsoLike className="" />
                <div className='row'>
                    <div className='p-4 col-12 col-lg-6 m-auto'>
                        <CartPageFaq />
                    </div>
                </div>
                <SPFooter />
            </>}





        </div>
    )
}

export default CartHold