import React from 'react'

const BsText = () => {
    return (
        <div>

            <p className='fs-3 fw-bold text-center mt-4'> OUR BEST SELLERS </p>

        </div>
    )
}

export default BsText